import React, {Component} from 'react'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import DeleteAlert from './delete-alert'

class MemberListItem extends Component {

	constructor(props){
		super(props)
		this.state = {
			user: {},
			message: '',
			roleId: this.props.roleId,
			originalRoleId: this.props.roleId
		}
		this.removeMember = this.removeMember.bind(this)
		this.handleRemoveMemberSuccess = this.handleRemoveMemberSuccess.bind(this)
		this.hideAlert = this.hideAlert.bind(this)
		this.handleRoleChange = this.handleRoleChange.bind(this)
		this.saveRoleChange = this.saveRoleChange.bind(this)
	}

	fetchUserData(){
		var success = this.dataHandler.bind(this)
		var url = '/users/' + this.props.userId 
		$.get(url).done(success)
	}
	
	componentDidMount(){
		this.fetchUserData()
	}

	componentDidUpdate(prevProps){
		if (prevProps.roleId !== this.props.roleId){
			this.setState({
				roleId: this.props.roleId,
				originalRoleId: this.props.roleId
			})
		}
	
		if (prevProps.userId !== this.props.userId){
			this.fetchUserData()
		}
	}

	dataHandler(data){
		this.setState({
			user: data
		})
	}

	showAlert(){
		this.setState({
			message: 'Are you sure you want to delete this Member?'
		})
	}

	hideAlert(){
		this.setState({ message: '' })
	}

	removeMember(){
		var url = '/members/' + this.props.memberId
		$.ajax({
			url: url,
			method: 'DELETE',
			dataType: 'json'
		}).done(this.handleRemoveMemberSuccess)
	}

	handleRemoveMemberSuccess(){
		this.props.removeHandler(this.props.key)
		this.hideAlert()
	}

	handleRoleChange(event) {
		this.setState({ roleId: event.target.value })
	}

	saveRoleChange() {
		var memberId = this.props.memberId
		var url = '/members/' + memberId
		var data = {
			userId: this.state.user.id,
			roleId: this.state.roleId,
			organizationId: this.props.organizationId
		}
		$.ajax({
			url: url,
			method: 'PUT',
			data: data
		}).done((response) => {
			// Update originalRoleId to reflect the saved roleId
			this.setState({ originalRoleId: this.state.roleId })
			// Optionally, refresh the member list
			this.props.fetch()
		})
	}

	render(){
		var memberIsCurrentUser = this.props.user.id === this.props.userId
		var showRemove = this.props.isAdmin || memberIsCurrentUser
		return (
			<li className='list-group-item'
				member-id={this.props.memberId}>
				<div className='row'>
					<div className='col-3 text-truncate mt-2'>
						<span>{this.state.user.firstName} {this.state.user.lastName}</span>
					</div>
					<div className='col-3 text-truncate mt-2'>
						<span>{this.state.user.email}</span>
					</div>
					<div className='col-3 text-truncate mt-2'>
						<span>{this.state.user.phone}</span>
					</div>
					<div className='col-2'>
					<select className='form-control'
						id={this.state.user.id} 
						member-id={this.props.memberId} 
						onChange={this.handleRoleChange} 
						value={this.state.roleId}
						disabled={!this.props.isAdmin}
						>
						<option value='2'>Member</option>
						<option value='1'>Admin</option>
					</select>
					{ this.state.roleId !== this.state.originalRoleId && 
						<button className='btn btn-primary mt-2' onClick={this.saveRoleChange}>Save</button>
					}
					</div>
					<div className='col-1 btn-group'>
						<button type='button' className='btn btn-outline-danger' 
							hidden={!showRemove}
							onClick = {this.showAlert.bind(this)}
							>
							<FontAwesomeIcon icon={ faTimes } />
						</button>
					</div>
				</div>
				<DeleteAlert message={this.state.message} confirmHandler={this.removeMember} cancelHandler={this.hideAlert}/>
			</li>
		)
	}
	
}

export default MemberListItem
