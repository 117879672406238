import React from 'react';

const UIDDisplay = ({ udi }) => (
  <div style={{
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    backgroundColor: '#fff',
    padding: '10px',
    // border: '1px solid #ccc',
  }}>
    UDI: {udi}
  </div>
);

export default UIDDisplay;