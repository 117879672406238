import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserEdit,
  faFilePdf,
  faEye,
  faSpinner,
  faRedo,
  faChartLine,
  faFileCsv,
  faFileArrowDown,
  faTrashCan,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

import Alert from './alert';
import $ from 'jquery';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const downloadEmployeeData = (url, name) => {
  fetch(url, {
    credentials: 'include',
  }).then((response) => {
    if (response.status !== 200) return;
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    });
    //window.location.href = response.url;
  });
};

class ReportListItem extends Component {
  constructor(props) {
    super(props);
    let settings = props.reportAlertSettings;
    let modifiedFields = {
      RDIMildLow: settings ? settings.fields.RDIMildLow : 5,
      RDIMildHigh: settings ? settings.fields.RDIMildHigh : 14,
      RDIModerateLow: settings ? settings.fields.RDIModerateLow : 15,
      RDIModerateHigh: settings ? settings.fields.RDIModerateHigh : 30,
      RDISevereLow: settings ? settings.fields.RDISevereLow : 30,

      BDMildLow: settings ? settings.fields.BDMildLow : 92,
      BDMildHigh: settings ? settings.fields.BDMildHigh : 88,
      BDSevereHigh: settings ? settings.fields.BDSevereHigh : 100,

      CTMildLow: settings ? settings.fields.CTMildLow : 0,
      CTMildHigh: settings ? settings.fields.CTMildHigh : 0,
      CTModerateLow: settings ? settings.fields.CTModerateLow : 0,
      CTModerateHigh: settings ? settings.fields.CTModerateHigh : 0,
      CTSevereLow: settings ? settings.fields.CTSevereLow : 0,

      ODI4SevereLow: settings ? settings.fields.ODI4SevereLow : 28.5,
      CT90SevereLow: settings ? settings.fields.CT90SevereLow : 7.2,
      MeanSPO2SevereHigh: settings ? settings.fields.MeanSPO2SevereHigh : 92.7,
    };
    if (settings) {
      for (const category in settings.fields) {
        if (settings.hasOwnProperty(category)) {
          const value = settings.fields[category];
          if (value) {
            modifiedFields[category] = parseFloat(value);
          }
        }
      }
    }

    this.state = {
      patient: {},
      analysis: null,
      pdf: null,
      pdfGenerating: false,
      analysisGenerating: false,
      interpreting: false,
      included: false,
      data: this.props.data,
      errorMessage: null,
      alertType: '',
      showRDIAlert: false,
      showBDAlert: false,
      showCTAlert: false,
      showVRTAlert: false,
      showPostOP: settings ? settings.showPostOP : false,
      RDIAlertColor: 'black',
      BDAlertColor: 'black',
      CTAlertColor: 'black',
      VRTAlertColor: 'black',
      RDIAlertType: 'far',
      BDAlertType: 'far',
      CTAlertType: 'far',
      VRTAlertType: 'far',
      fields: modifiedFields,
      showDeleteConfirmation: false,
    };
  }

  componentDidMount() {
    for (
      let i = 0;
      i < this.props.reports.state.defaultMultiNights.length;
      i++
    ) {
      const report = this.props.reports.state.defaultMultiNights[i];
      // All reports with suffiecient data should by default be included in multi-night reports
      if (report === this.state.data) {
        this.setState({
          included: true,
        });
      }
    }
    this.setAlerts('RDI4_OVERALL');
    this.setAlerts('SPO2_BASELINE_LOW');
    this.setAlerts('SPO2_INST_PCT');
    this.setAlerts('VRT');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.isIncludedInMulti(this.props.data);
      this.setState(
        {
          data: this.props.data,
          pdfGenerating: false,
        },
        () => {
          this.setAlerts('RDI4_OVERALL');
          this.setAlerts('SPO2_BASELINE_LOW');
          this.setAlerts('SPO2_INST_PCT');
          this.setAlerts('VRT');
        },
      );
    }
  }

  setAlerts(name) {
    let fields = this.state.fields;
    if (this.state.data.data) {
      let data = this.state.data.data[name];
      if (name === 'RDI4_OVERALL') {
        if (this.props.reportAlertSettings.showRDIAlert === false) {
          this.setState({
            RDIAlertColor: 'black',
            showRDIAlert: false,
            RDIAlertType: 'far',
          });
          return;
        }
        if (data >= fields.RDIMildLow && data < fields.RDIMildHigh) {
          if (fields.RDIMildLow === 0 && fields.RDIMildHigh === 0) {
            this.setState({
              RDIAlertColor: 'black',
              showRDIAlert: false,
              RDIAlertType: 'far',
            });
          } else {
            this.setState({
              RDIAlertColor: 'GoldenRod',
              showRDIAlert: true,
              RDIAlertType: 'far',
            });
          }
        } else if (
          data >= fields.RDIModerateLow &&
          data < fields.RDIModerateHigh
        ) {
          if (
            fields.RDIModerateLow === 0 &&
            fields.RDIModerateHigh === 0 &&
            fields.RDIMildLow === 0 &&
            fields.RDIMildHigh === 0
          ) {
            this.setState({
              RDIAlertColor: 'black',
              showRDIAlert: false,
              RDIAlertType: 'far',
            });
          } else {
            this.setState({
              RDIAlertColor: 'red',
              showRDIAlert: true,
              RDIAlertType: 'far',
            });
          }
        } else if (data >= fields.RDISevereLow) {
          if (
            fields.RDIModerateLow === 0 &&
            fields.RDIModerateHigh === 0 &&
            fields.RDIMildLow === 0 &&
            fields.RDIMildHigh === 0 &&
            fields.RDISevereLow === 0
          ) {
            this.setState({
              RDIAlertColor: 'black',
              showRDIAlert: false,
              RDIAlertType: 'far',
            });
          } else {
            this.setState({
              RDIAlertColor: 'red',
              showRDIAlert: true,
              RDIAlertType: 'fas',
            });
          }
        } else {
          this.setState({
            showRDIAlert: false,
            RDIAlertColor: 'black',
          });
        }
      }
      if (name === 'SPO2_BASELINE_LOW') {
        if (this.props.reportAlertSettings.showBDAlert === false) {
          this.setState({
            BDAlertColor: 'black',
            showBDAlert: false,
            BDAlertType: 'far',
          });
          return;
        }
        if (data >= fields.BDMildLow && data < fields.BDMildHigh) {
          if (fields.BDMildLow === 0 && fields.BDMildHigh === 0) {
            this.setState({
              BDAlertColor: 'black',
              showBDAlert: false,
              BDAlertType: 'far',
            });
          } else {
            this.setState({
              BDAlertColor: 'GoldenRod',
              showBDAlert: true,
              BDAlertType: 'far',
            });
          }
        } else if (data <= fields.BDSevereHigh) {
          if (
            fields.BDMildLow === 0 &&
            fields.BDMildHigh === 0 &&
            fields.BDSevereHigh === 0
          ) {
            this.setState({
              BDAlertColor: 'black',
              showBDAlert: false,
              BDAlertType: 'far',
            });
          } else {
            this.setState({
              BDAlertColor: 'red',
              showBDAlert: true,
              BDAlertType: 'fas',
            });
          }
        } else {
          this.setState({
            showBDAlert: false,
            BDAlertColor: 'black',
          });
        }
      }
      if (name === 'SPO2_INST_PCT') {
        if (this.props.reportAlertSettings.showCTAlert === false) {
          this.setState({
            CTAlertColor: 'black',
            showCTAlert: false,
            CTAlertType: 'far',
          });
          return;
        }
        if (data >= fields.CTMildLow && data < fields.CTMildHigh) {
          if (fields.CTMildLow === 0 && fields.CTMildHigh === 0) {
            this.setState({
              CTAlertColor: 'black',
              showCTAlert: false,
              CTAlertType: 'far',
            });
          } else {
            this.setState({
              CTAlertColor: 'GoldenRod',
              showCTAlert: true,
              CTAlertType: 'far',
            });
          }
        } else if (
          data >= fields.CTModerateLow &&
          data < fields.CTModerateHigh
        ) {
          if (
            fields.CTMildLow === 0 &&
            fields.CTMildHigh === 0 &&
            fields.CTModerateLow === 0 &&
            fields.CTModerateHigh === 0
          ) {
            this.setState({
              CTAlertColor: 'black',
              showCTAlert: false,
              CTAlertType: 'far',
            });
          } else {
            this.setState({
              CTAlertColor: 'red',
              showCTAlert: true,
              CTAlertType: 'far',
            });
          }
        } else if (data >= fields.CTSevereLow) {
          if (
            fields.CTMildLow === 0 &&
            fields.CTMildHigh === 0 &&
            fields.CTModerateLow === 0 &&
            fields.CTModerateHigh === 0 &&
            fields.CTSevereLow === 0
          ) {
            this.setState({
              CTAlertColor: 'black',
              showCTAlert: false,
              CTAlertType: 'far',
            });
          } else {
            this.setState({
              CTAlertColor: 'red',
              showCTAlert: true,
              CTAlertType: 'fas',
            });
          }
        } else {
          this.setState({
            showCTAlert: false,
            CTAlertColor: 'black',
          });
        }
      }
      if (name === 'VRT') {
        if (data < '04:00:00') {
          this.setState({
            VRTAlertColor: 'red',
            VRTAlertType: 'fas',
            showVRTAlert: true,
          });
        } else {
          this.setState({
            VRTAlertColor: 'black',
            showVRTAlert: false,
          });
        }
      }
    } else {
      this.setState({
        showRDIAlert: false,
        RDIAlertColor: 'black',
        showBDAlert: false,
        BDAlertColor: 'black',
        showCTAlert: false,
        CTAlertColor: 'black',
        showVRTAlert: false,
      });
    }
  }

  // Determines if the report associated with this list item is included in the includeInMultiNight array
  isIncludedInMulti(report) {
    var isIncluded = false;
    this.props.includeInMultiNight.forEach((includedReport) => {
      if (includedReport.id === report.id) {
        isIncluded = true;
      }
    });
    this.setState({
      included: isIncluded,
    });
  }

  generatePdf(event) {
    var success = this.handlePdf.bind(this);
    var error = this.handleError.bind(this);
    this.setState({
      pdfGenerating: true,
    });
    $.post({
      url: '/pdf/' + this.state.data.id,
    })
      .done(success)
      .fail(error);
  }

  handleAnalysis(data) {
    this.setState(
      {
        analysisGenerating: false,
        data: data,
      },
      () => {
        this.props.reports.getReports();
      },
    );
  }

  handlePdf(data) {
    this.setState({
      pdf: data.pdf,
      data: data,
      pdfGenerating: false,
    });
  }

  interpretationChangeHandler(event) {
    this.setState({
      interpretation: event.target.value,
    });
  }

  submitInterpretation() {
    var url = '/reports/' + this.state.data.id;
    var success = this.handleInterpretation.bind(this);
    $.ajax({
      method: 'PUT',
      url: url,
      data: {
        interpretation: this.state.interpretation,
      },
    }).done(success);
  }

  deletereport() {
    var url = '/reports/' + this.state.data.id;
    var success = this.handleDelete.bind(this);
    var fail = this.handleError.bind(this);
    $.ajax({
      method: 'DELETE',
      url: url,
    }).done(success).fail(fail);
  }

  handleDelete() {
    this.props.reports.removeReport(this.state.data.id);
  }
  

  handleInterpretation(data) {
    // close modal
    alert('Report interpretation updated!');
  }

  getDataValue(name) {
    var data = this.state.data.data;
    for (var key in data) {
      if (key === name) {
        return data[key];
      }
    }
  }

  hasValidData() {
    if (this.state.data.data) {
      return true;
    } else return false;
  }

  toggleMultiNightInclude(event) {
    var isChecked = event.target.checked;
    var data = this.state.data;
    if (!this.hasValidData()) {
      return;
    }
    if (isChecked) {
      this.props.reports.addToMultiNightInclusion(data);
    } else {
      this.props.reports.removeFromMultiNightInclusion(data);
    }

    this.setState({
      included: !this.state.included,
    });
  }

  renderPostOpAlerts() {
    if (!this.state.data.data) return;
    const data = this.state.data.data;
    const ODI4 = this.getDataValue('ODI4');
    const CT90 = parseFloat(this.getDataValue('SPO2_LT90PCT')).toFixed(1);
    const MeanSP02 = this.getDataValue('SPO2_MEAN');
    const VRT = data.VRT;

    let ODItext = <p></p>;
    let CTtext = <p></p>;
    let SPO2text = <p></p>;
    if (ODI4 > this.state.fields.ODI4SevereLow) {
      ODItext = <p>ODI4 (Events/hr): {ODI4}</p>;
    }
    if (CT90 > this.state.fields.CT90SevereLow) {
      CTtext = (
        <p>
          CT{'<'}90 (% of VRT): {CT90}%
        </p>
      );
    }
    if (MeanSP02 < this.state.fields.MeanSPO2SevereHigh) {
      SPO2text = <p>Mean SpO2 (%): {MeanSP02}%</p>;
    }

    if (VRT >= '04:00:00') {
      if (ODI4 > 28.5 || CT90 > 7.2 || MeanSP02 < 92.7) {
        return (
          <div>
            <i
              className={`fas fa-exclamation-triangle`}
              data-tip
              data-for={'postOpAlert' + this.props.data.id}
              style={{ color: 'red' }}
            ></i>
            <ReactTooltip
              id={'postOpAlert' + this.props.data.id}
              effect={'solid'}
              multiline={true}
            >
              {ODItext}
              {CTtext}
              {SPO2text}
            </ReactTooltip>
          </div>
        );
      }
    } else {
      return <span>---</span>;
    }
  }

  handleError(error) {
    this.setState({
      errorMessage:
        'Failed to Generate Report. Server error: ' +
        error.responseJSON.error.message,
      alertType: 'alert-danger',
      pdfGenerating: false,
    });
  }

  removeAlert() {
    this.setState({
      errorMessage: null,
    });
  }

  render() {
    var data = this.props.data;
    var patient = data.patient;
    var generatePdf = this.generatePdf.bind(this);
    var toggleMultiNightInclude = this.toggleMultiNightInclude.bind(this);
    var hasPdf = data.pdf || this.state.pdf;
    var hasEdf = data.edf || this.state.edf;
    var pdfLink = '/pdf/' + this.state.data.id;
    var edfLink = '/edf/' + this.state.data.id;
    var patientLink = patient.id ? '/patients/' + patient.id : '';
    var startTime;
    var endTime;
    if (data.data) {
      startTime = data.data.STUDY_START;
      endTime = data.data.STUDY_END;
    } else {
      startTime = data.startTime;
      endTime = data.endTime;
    }
    var date = moment(startTime).format('MMMM DD, YYYY');
    var time =
      moment(startTime).format('h:mm A') +
      ' - ' +
      moment(endTime).format('h:mm A');
    var reportLink = '/reports/' + data.id;
    var analysisLink = '/analysis/' + data.id;
    var chartLink = '/chart/' + data.guid + '/' + patient.id;
    var generatingPdf = this.state.pdfGenerating;
    var generatePdfIcon = generatingPdf ? faSpinner : faRedo;
    var hidePatient = window.location.hash.includes('patients') ? true : false;
    var isValidReport = this.hasValidData();
    var background = isValidReport ? '' : 'text-danger';
    var removeAlert = this.removeAlert.bind(this);

    var referrer = data.users.find(function (u) {
      return u.id === data.referringId;
    });
    var interpreter = data.users.find(function (u) {
      return u.id === data.interpretingId;
    });

    var referrerName = referrer
      ? referrer.firstName + ' ' + referrer.lastName
      : '-----';
    var interpreterName = interpreter
      ? interpreter.firstName + ' ' + interpreter.lastName
      : '-----';

    let VRT = this.getDataValue('VRT');
    // Post-Op veriables and settings
    let showPostOP = this.state.showPostOP;



    return (
      <li
        className={`list-group-item border border-muted rounded text-center`}
        style={{ padding: 2 }}
      >
        <div className="row">
          {/* Date Field */}
          <div className="col-2">
            <div className="row ">
              <div className="col">
                <span
                  className={`text-center ${background}`}
                  style={{ fontSize: '14px' }}
                >
                  {date}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span className={`${background}`} style={{ fontSize: '12px' }}>
                  {time}
                </span>
              </div>
            </div>
          </div>

          {/* Patient Name Field */}
          <div className="col-1 text-truncate" hidden={hidePatient}>
            <div className="row ">
              <div className="col">
                <span className={`text-center ${background}`}>
                  {patient.firstName}
                </span>
              </div>
            </div>
            <div className="row ">
              <div className="col">
                <span className={`text-center ${background}`}>
                  {patient.lastName}
                </span>
              </div>
            </div>
          </div>

          {/* Physician Field */}
          <div
            className="col-1 text-truncate"
            hidden={!this.props.reports.state.showPhysicians}
          >
            <div
              className="row justify-content-center"
              style={{ fontSize: '14px' }}
            >
              Referrer: {referrerName}
            </div>
            <div
              className="row justify-content-center"
              style={{ fontSize: '14px' }}
            >
              Interpreter: {interpreterName}
            </div>
          </div>

          {/* RDI Column*/}
          <div className="col-1 mt-2 text-truncate">
            <i
              className={`${this.state.RDIAlertType} fa-exclamation-triangle`}
              style={{ color: this.state.RDIAlertColor }}
              hidden={!this.state.showRDIAlert}
            ></i>
            <span style={{ color: this.state.RDIAlertColor }}>
              {' '}
              {this.getDataValue('RDI4_OVERALL')}
            </span>
          </div>

          {/* BD Column*/}
          <div className="col-1 mt-2 text-truncate">
            <i
              className={`${this.state.BDAlertType} fa-exclamation-triangle`}
              style={{ color: this.state.BDAlertColor }}
              hidden={!this.state.showBDAlert}
            ></i>
            <span style={{ color: this.state.BDAlertColor }}>
              {' '}
              {this.getDataValue('SPO2_BASELINE_LOW')}
            </span>
          </div>

          {/* CT Column*/}
          <div className="col-1 mt-2 text-truncate">
            <i
              className={`${this.state.CTAlertType} fa-exclamation-triangle`}
              style={{ color: this.state.CTAlertColor }}
              hidden={!this.state.showCTAlert}
            ></i>
            <span style={{ color: this.state.CTAlertColor }}>
              {' '}
              {parseFloat(this.getDataValue('SPO2_INST_PCT')).toFixed(0)}{' '}
            </span>
          </div>

          {/* Post Op Column*/}
          <div className="col-2 mt-2 text-truncate" hidden={!showPostOP}>
            {this.renderPostOpAlerts()}
          </div>

          {/* VRT Column*/}
          <div className="col-1 mt-2 text-truncate">
            <i
              className={`${this.state.VRTAlertType} fa-exclamation-triangle`}
              style={{ color: this.state.VRTAlertColor }}
              hidden={!this.state.showVRTAlert}
            ></i>
            <span style={{ color: this.state.VRTAlertColor }}> {VRT}</span>
          </div>

          {/* Multi-Night Selector */}
          <div className="col-1 mt-2 form-check" hidden={!hidePatient}>
            <input
              type="checkbox"
              className="form-check-input"
              onChange={toggleMultiNightInclude}
              checked={this.state.included}
            />
          </div>

          {/* Action Buttons Field */}
          <div className="col-3 mt-2">
            <div className="col align-content-center " role="group">
            
              {/* <button
                className="btn btn-sm btn-success"
                onClick={generatePdf}
                title="Generate Report"
              >
                <FontAwesomeIcon
                  className={'fa-fw ' + (generatingPdf ? 'fa-spin' : '')}
                  icon={generatePdfIcon}
                />
              </button> */}
              

              <Link
                className="btn btn-sm btn-primary"
                to={patientLink}
                title="Edit Patient"
                hidden={hidePatient}
              >
                <FontAwesomeIcon className="fa-fw" icon={faUserEdit} />
              </Link>


              <a
                className={
                  'btn btn-sm btn-danger ' + (hasPdf ? '' : 'disabled')
                }
                download={this.props.data.guid + '.pdf'}
                onClick={(e) => {
                  e.preventDefault();
                  downloadEmployeeData(pdfLink, this.props.data.guid + '.pdf');

                  setTimeout(() => {
                    downloadEmployeeData(
                      pdfLink + '?multi=true',
                      this.props.data.guid + '.multichannel.pdf',
                    );
                  }, 5000);
                }}
                href={pdfLink}
                title="Download PDF"
              >
                <FontAwesomeIcon className="fa-fw" icon={faFilePdf} />
              </a>

              
              {/* <a
                className={
                  'btn btn-sm btn-primary' + (hasEdf ? '' : 'disabled')
                }
                download={this.props.data.guid + '.edf'}
                onClick={(e) => {
                  e.preventDefault();
                  downloadEmployeeData(edfLink, this.props.data.guid + '.edf');
                  }
                }
                href={edfLink}
                title="Download EDF"
              >
                <FontAwesomeIcon className="fa-fw" icon={faFileArrowDown} />
              </a> */}
            


              <a
                id="multichannel_link"
                download={this.props.data.guid + '.multichannel.pdf'}
                href={pdfLink + '?multi=true'}
                style={{ display: 'none' }}
              >
                <FontAwesomeIcon className="fa-fw" icon={faFilePdf} />
              </a>


              <button
                className={'btn btn-sm btn-info'}
                onClick={() => {
                  window.location.hash = reportLink;
                }}
                title="View Report Notes"
              >
                <FontAwesomeIcon className="fa-fw" icon={faEye} />
              </button>

              <button
                className={'btn btn-sm btn-danger'}
                onClick={() => {
                  this.setState({
                    showDeleteConfirmation: true,
                    });
                }}
                title="Delete Report"
              >
                <FontAwesomeIcon className="fa-fw" icon={faTrashCan} />
              </button>

              
              {/* <button
                className={
                  'btn btn-sm btn-secondary' + (hasPdf ? '' : ' disabled')
                }
                onClick={() => {
                  window.open('/#' + chartLink, '_blank').focus();
                  //window.location.hash = chartLink;
                }}
                title="View Chart"
              >
                <FontAwesomeIcon className="fa-fw" icon={faChartLine} />

              </button> */}
              
            </div>
          </div>
        </div>
        <Alert
          message={this.state.errorMessage}
          type={this.state.alertType}
          onExit={removeAlert}
        />
        {this.state.showDeleteConfirmation && (
          <div>
            {/* Modal Backdrop */}
            <div className="modal-backdrop fade show"></div>
            {/* Modal Dialog */}
            <div
              className="modal fade show"
              tabIndex="-1"
              role="dialog"
              style={{ display: 'block' }}
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Confirm Deletion</h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() =>
                        this.setState({ showDeleteConfirmation: false })
                      }
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>
                      Are you sure you want to delete this report? This action
                      cannot be undone.
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        this.deletereport();
                        this.setState({ showDeleteConfirmation: false });
                      }}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() =>
                        this.setState({ showDeleteConfirmation: false })
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </li>
    );
  }
}

export default ReportListItem;
