import React, { Component } from 'react'
import Interpretation from './interpretation'
import $ from 'jquery'
import PatientDetails from './patient-details'
import Charts from './charts'
import { saveAs } from 'file-saver'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowDown, faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons'


class Report extends Component {

	constructor(props) {
		super(props)
		this.path = '/reports'
		var id = parseInt(props.location.match.params.id)
		this.url = props.location.match.url
		this.state = {
			generating: false,
			data: {
				id: id,
				data:[]
			}
		}
		this.getData = this.getData.bind(this)
		this.handleData = this.handleData.bind(this)
		this.generateAnalysis = this.generateAnalysis.bind(this)
		this.handleAnalysis = this.handleAnalysis.bind(this)

	}

	componentDidMount() {
		this.getData()
	}

	componentDidUpdate(props, state) {
		var dataIdChanged = state.data.id !== this.state.data.id
		if (dataIdChanged) {
			this.url = this.path + '/' + this.state.data.id
			this.props.location.history.push(this.url)
		}
	}

	getData() {
		if (this.state.data.id === 0) return
		$.ajax({
			method: 'GET',
			url: this.url
		}).done(this.handleData)
	}

	handleData(data) {
		this.setState({
			data: data
			
		})
		if (!data.id || data.deleted) {
			this.props.location.history.push(this.path)
		}
	}

	generateAnalysis() {
		var patient = this.state.patient
		var success = this.handleAnalysis.bind(this)
		var url = '/analysis/' + this.state.data.id
		$.post({
			url: url,
			data: {
				patient: patient
			}
		}).done(success)
		this.setState({
			analysisGenerating: true
		})
	}

	downloadData() {

		var data = this.state.data.data

		if(data) {
			const jsonData = JSON.stringify(data, null, 2)
			const blob = new Blob([jsonData], {type: 'application/json' })
			saveAs(blob, 'Analysis.json')
		}else{
			alert("No data avalible")
		}

		
		
		
		
		
		// const rows =[]
		// const headers = Object.keys(data[0])

		// rows.push(headers.join(','))

		// data.forEach(item => {
		// 	const values = headers.map(header => item[header]);
		// 	rows.push(values.join(','))
		// })

		// const csvString = rows.join('\n')

		// const encodeURi = encodeURI('data:text/csv;charset=utf-8,{csvSting}')

	}

	handleAnalysis(data) {
		this.setState({
			data: data,
			analysisGenerating: false
		})
	}

	renderForm(analysis) {
		var output = []
		for (var key in analysis) {
			var value = analysis[key] != null ? analysis[key] : 'null'
			output.push(
				<div className='form-group col-2 text-truncate' key={key}>
					<label className='form-control-sm' type='text'>{key} </label>
					<input className='form-control' disabled value={value} />
				</div>
			)
		}
		return output
	}
	

	renderChart() {
		var data = this.state.data.data
		if (data) {
			var output = []
			for (var key in data) {
				if (key === "RDI (hr)") {
					output.push(
						<Charts data={key}></Charts>
					)
				}
			}
			return (
				<Charts data={data}></Charts>
			)
		}
		else {
			return
		}
	}

	render() {
		var report = this.state.data
		var analysis = report.data || {}
		var form = this.renderForm(analysis)
		var generatingAnalysis = this.state.analysisGenerating
		var generateAnalysisIcon = generatingAnalysis ? faSpinner : faDownload
		var downloading = this.state.downloading
		var downloadIcon = downloading ? faSpinner : faCloudArrowDown
		return (
			<div id='report'>

				<div className='row mb-4'>
					<div className='col'>
						<Interpretation report={this.state.data} user={this.props.user} />
					</div>
				</div>
				<div className='row mb-4'>
					<div className='col'>
						<PatientDetails data={{ id: this.state.data.patientId }} user={this.props.user} />
					</div>
				</div>
				<div className='row mb-4'>
					<div className='col'>
						<div className='card'>
							<div className='card-header'>
								<div className='row align-items-center'>
									<div className='col'>
										<span className='h4'>Analysis</span>
									</div>
									<div className='col text-right'>
										<button className='btn btn-outline-primary btn-sm text-center'
											onClick={this.generateAnalysis}
											title='Generate Analysis'
										>
											<FontAwesomeIcon className={'fa-fw ' + (generatingAnalysis ? 'fa-spin' : '')}
												icon={generateAnalysisIcon} />
										</button>
									</div>
									<div className='col text-right'>
										<button className='btn btn-outline-primary btn-sm text-center'
											onClick={this.downloadData.bind(this)}
											title='Download Analysis'
										>
											<FontAwesomeIcon className={'fa-fw ' + (generatingAnalysis ? 'fa-spin' : '')}
												icon={downloadIcon} />
										</button>
									</div>
								</div>
							</div>
							<div className='card-body'>
								<div className='form-horizontal'>
									<div className='form-row'>
										{form}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		)
	}

}

export default Report
