import fconf from '../report-composer/conf.js';
import { CompareReports_2_PDF } from '../report-composer/compare-reports';
import React, { Component } from 'react';
import ReportListItem from './report-list-item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import moment from 'moment';
import Paginator from './paginator';
import GenericChart from './generic-chart';
import { report } from 'process';
import { is } from 'immutable';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      filteredReports: [],
      limitedReports: [],
      fromDate: '',
      toDate: '',
      limit: 10,
      offset: 0,
      page: 1,
      hidePending: false,
      hideComplete: false,
      hideInvalid: true,
      totalReports: 0,
      currentPage: 1,
      includeInMultiNight: [],
      defaultMultiNights: [],
      showPhysicians: false,
      reportAlertSettings: {
        showPostOP: false,
        showRDIAlert: true,
        showCTAlert: false,
        showBDAlert: true,
        fields: {
          RDIMildLow: {
            category: 'RDI',
            tier: 'Mild',
            value: 5,
            rangePlacement: 'Low',
          },
          RDIMildHigh: {
            category: 'RDI',
            tier: 'Mild',
            value: 15,
            rangePlacement: 'High',
          },
          RDIModerateLow: {
            category: 'RDI',
            tier: 'Moderate',
            value: 15,
            rangePlacement: 'Low',
          },
          RDIModerateHigh: {
            category: 'RDI',
            tier: 'Moderate',
            value: 30,
            rangePlacement: 'High',
          },
          RDISevereLow: {
            category: 'RDI',
            tier: 'Severe',
            value: 30,
            rangePlacement: 'Low',
          },
          BDMildLow: {
            category: 'BD',
            tier: 'Mild',
            value: 88,
            rangePlacement: 'Low',
          },
          BDMildHigh: {
            category: 'BD',
            tier: 'Mild',
            value: 92,
            rangePlacement: 'High',
          },
          BDSevereHigh: {
            category: 'BD',
            tier: 'Severe',
            value: 88,
            rangePlacement: 'High',
          },
          CTMildLow: {
            category: 'CT',
            tier: 'Mild',
            value: 5,
            rangePlacement: 'Low',
          },
          CTMildHigh: {
            category: 'CT',
            tier: 'Mild',
            value: 15,
            rangePlacement: 'High',
          },
          CTModerateLow: {
            category: 'CT',
            tier: 'Moderate',
            value: 15,
            rangePlacement: 'Low',
          },
          CTModerateHigh: {
            category: 'CT',
            tier: 'Moderate',
            value: 30,
            rangePlacement: 'High',
          },
          CTSevereLow: {
            category: 'CT',
            tier: 'Severe',
            value: 30,
            rangePlacement: 'Low',
          },
          ODI4SevereLow: {
            category: 'ODI4',
            tier: 'Severe',
            value: 28.5,
            rangePlacement: 'Low',
          },
          CT90SevereLow: {
            category: 'CT90',
            tier: 'Severe',
            value: 7.2,
            rangePlacement: 'Low',
          },
          MeanSPO2SevereHigh: {
            category: 'MeanSp02',
            tier: 'Severe',
            value: 92.7,
            rangePlacement: 'Low',
          },
        },
      },
      fetchingReports: false,
      sortBy: 'STUDY_START',
      sortOrder: 1,
    };
    this.togglePending = this.togglePending.bind(this);
    this.toggleComplete = this.toggleComplete.bind(this);
    this.toggleInvalid = this.toggleInvalid.bind(this);
    this.renderReports = this.renderReports.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.renderListItem = this.renderListItem.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.toggleShowPhysicians = this.toggleShowPhysicians.bind(this);
  }

  componentDidMount() {
    this.getOrganization();
    this.getReports();
  }

  componentDidUpdate(prevProps, prevState) {
    // Refresh reports if the user organization changes or if reports if view a different patient
    if (
      prevProps.organization.id !== this.props.organization.id ||
      (this.props.patient && prevProps.patient.id !== this.props.patient.id)
    ) {
      this.setState({
        reports: [],
        offset: 0,
      });
      this.getReports();
    }
  }

  getOrganization() {
    var success = this.handleData.bind(this);
    $.ajax({
      method: 'GET',
      url: '/organizations/' + this.props.organization.id,
    }).done(success);
  }

  // Uses response by getOrganization to establish the alert thresholds for reports
  handleData(data) {
    let settings = data.reportAlertSettings;
    if (typeof settings === 'string') {
      settings = JSON.parse(settings);
    }
    if (!settings) return;
    this.setState({
      reportAlertSettings: settings,
    });
  }

  getReports() {
    var success = this.handleReports.bind(this);
    var orgId = this.props.organization.id || null;
    var data = {
      startTime: {},
      offset: this.state.offset,
      limit: 100,
      order: 'startTime DESC',
      include: '[{"model":"patient"},{"model":"user"}]',
    };
    if (orgId) {
      data.organizationId = orgId;
    }
    var fromDate = this.state.fromDate;
    var toDate = this.state.toDate;
    if (fromDate) {
      data.startTime.$gte = fromDate;
    }
    if (toDate) {
      data.startTime.$lte = moment(toDate).add(1, 'days').format('YYYY-MM-DD');
    }
    if (this.props.patient) {
      data.patientId = this.props.patient.id;
    }
    this.setState({
      fetchingReports: true,
      offset: this.state.offset + 100,
    });
    $.ajax({
      url: '/reports',
      data: data,
    }).done(success);
  }

  handleReports(data) {
    // Add newly incoming reports to the reports array
    // This is needed as long as calling reports is executed in chunks
    let newReports = this.state.reports.slice();
    data.rows.forEach((newReport) => {
      newReports.push(newReport);
    });

    // Ensures report ids are not repeating
    newReports = newReports.filter(
      (report, index, array) =>
        array.findIndex((arrayReport) => arrayReport.id === report.id) ===
        index,
    );

    // Filter through all the reports and add valid ones to the default multi night array
    let validReports = newReports.filter((report, index, array) => {
      return (
        report.data &&
        report.data.STUDY_START &&
        report.valid &&
        report.data.VRT > '04:00:00'
      );
    });
    let orderedMultiNightReports = validReports.sort(
      (a, b) => a.data.STUDY_START > b.data.STUDY_START,
    );

    // Used to determine if there are more reports that have not yet been recieved by the server
    let hasMore = data.count > newReports.length ? true : false;

    // newReports is the combination of reports gathered in previous batches and the current batch
    this.setState(
      {
        reports: newReports,
        defaultMultiNights: validReports,
        includeInMultiNight: orderedMultiNightReports,
        fetchingReports: hasMore,
      },
      this.applyFilters.bind(this),
    );
  }

removeReport(reportId) {
  const reports = this.state.reports.filter(report => report.id !== reportId);
  const includeInMultiNight = this.state.includeInMultiNight.filter(report => report.id !== reportId);  
  this.setState({ 
    reports: reports, 
    includeInMultiNight: includeInMultiNight 
  }, this.applyFilters);
}



  // Adds reports to a final array only if they match the checked filter criteria
  applyFilters() {
    var reports = this.state.reports;

    if (this.state.hidePending) {
      reports = this.hidePendingReports(reports);
    }
    if (this.state.hideComplete) {
      reports = this.hideCompleteReports(reports);
    }
    if (this.state.hideInvalid) {
      reports = this.hideInvalidReports(reports);
    }
    this.setState(
      {
        filteredReports: reports,
      },
      () => {
        if (this.state.fetchingReports) {
          this.getReports();
        } else {
          this.setState({
            offset: 0,
          });
        }
      },
    );
  }

  hideInvalidReports(data) {
    return data.reduce(function (sum, report) {
      if (report.valid) sum.push(report);
      return sum;
    }, []);
  }

  hideCompleteReports(data) {
    return data.reduce(function (sum, report) {
      if (!report.interp) sum.push(report);
      return sum;
    }, []);
  }

  hidePendingReports(data) {
    return data.reduce(function (sum, report) {
      if (report.interp) sum.push(report);
      return sum;
    }, []);
  }

  // This function is sent to the paginator which will be called on each report the Paginator determines should be shown
  renderReports(data) {
    this.setState({
      limitedReports: data,
    });
  }

  // Returns a ReportListItem for each report in limitedReports state
  renderListItem(data, index) {
    return (
      <ReportListItem
        key={data.id}
        organization={this.props.organization}
        data={data}
        reports={this}
        includeInMultiNight={this.state.includeInMultiNight}
        reportAlertSettings={this.state.reportAlertSettings}
      />
    );
  }

  isIncludedInMultiNight(report) {
    var isIncluded = false;
    this.state.includeInMultiNight.forEach((includedReport) => {
      if (includedReport.id === report.id) {
        isIncluded = true;
      }
    });
    return isIncluded;
  }

  handleSearchChange(event) {
    var data = {};
    data[event.target.name] = event.target.value;
    this.setState(data);
  }

  handleSearch(event) {
    event.preventDefault();
    var fromDate = this.state.fromDate;
    var toDate = this.state.toDate;
    this.setState(
      {
        fromDate: fromDate,
        toDate: toDate,
        offset: 0,
        reports: [],
      },
      this.getReports.bind(this),
    );
  }

  togglePending() {
    this.setState(
      {
        hidePending: !this.state.hidePending,
      },
      this.applyFilters,
    );
  }

  toggleComplete() {
    this.setState(
      {
        hideComplete: !this.state.hideComplete,
      },
      this.applyFilters,
    );
  }

  toggleInvalid() {
    this.setState(
      {
        hideInvalid: !this.state.hideInvalid,
      },
      this.applyFilters,
    );
  }

  toggleShowPhysicians() {
    this.setState({
      showPhysicians: !this.state.showPhysicians,
    });
  }

  addToMultiNightInclusion(report) {
    if (!report.data) return;
    var multinights = this.state.includeInMultiNight;
    multinights.push(report);
    this.setState({
      includeInMultiNight: multinights.sort(
        (a, b) => a.data.STUDY_START > b.data.STUDY_START,
      ),
    });
  }

  removeFromMultiNightInclusion(report) {
    var multinights = this.state.includeInMultiNight;
    const index = multinights.findIndex(
      (listedReport) => listedReport.id === report.id,
    );
    multinights.splice(index, 1);
    this.setState({
      includeInMultiNight: multinights,
    });
  }

  generateMultiNight() {
    var success = this.handleMultiNights.bind(this);
    var reportIds = [];
    this.state.includeInMultiNight.forEach((report) => {
      reportIds.push(report.id);
    });
    $.ajax({
      method: 'POST',
      url: '/multi-night-reports',
      data: {
        containedReportIds: reportIds,
        organizationId: this.props.organization.id,
        patientId: this.props.patient.id,
      },
    }).done(success);
  }

  sortByLastName() {
    let sortedReports = this.state.reports;
    const compare = this.compare.bind(this);
    sortedReports.sort(compare);
    this.setState(
      {
        reports: sortedReports, 
      },
      this.applyFilters
    );
  }

  handleMultiNights(res) {
    var adm = localStorage.getItem('systemAdmin');
    fconf.ENABLE_REP_EDITOR = adm + '' === '1';
    if (fconf.ENABLE_REP_EDITOR) {
      window.location.hash = '/multi-night-reports/' + res.id;
    } else {
      CompareReports_2_PDF({
        location: {
          location: {
            pathname: '/multi-night-reports/' + res.id,
          },
          match: {
            params: {
              id: res.id,
            },
          },
        },
        organization: { id: this.props.organization.id },
      });
    }
  }


  setSortBy(e) {
    let sortOrder = 1;
    if (this.state.sortBy === e.target.id) {
      if (this.state.sortOrder === 1) {
        sortOrder = -1;
      }
    }

    this.setState(
      {
        sortBy: e.target.id,
        sortOrder: sortOrder,
      },
      this.sortReports.bind(this),
    );
  }

  sortReports() {
    let sortedReports = this.state.reports;
    const compare = this.compare.bind(this);
    sortedReports.sort(compare);
    this.setState(
      {
        reports: sortedReports,
      },
      this.applyFilters,
    );
  }

  compare(a, b) {
    let comparison = 0;
    let reportA = a.data?.[this.state.sortBy] ?? '';
    let reportB = b.data?.[this.state.sortBy] ?? '';

    if (this.state.sortBy === 'RDI4_OVERALL') {
      reportA = a.data?.RDI4_OVERALL ?? 0;
      reportB = b.data?.RDI4_OVERALL ?? 0;
    }
    if (this.state.sortBy === 'SPO2_BASELINE_LOW') {
      reportA = a.data?.SPO2_BASELINE_LOW ?? 0;
      reportB = b.data?.SPO2_BASELINE_LOW ?? 0;
    }
    if (this.state.sortBy === 'SPO2_INST_PCT') {
      reportA = a.data?.SPO2_INST_PCT ?? 0;
      reportB = b.data?.SPO2_INST_PCT ?? 0;
    }
    if (this.state.sortBy === 'VRT') {
      reportA = a.data?.VRT ?? 0;
      reportB = b.data?.VRT ?? 0;
    }
    if (this.state.sortBy === 'STUDY_START') {
      reportA = a.data?.STUDY_START ?? '';
      reportB = b.data?.STUDY_START ?? '';
    }
    if (this.state.sortBy === 'lastName') {
      reportA = a.patient !== null ? a.patient.lastName.toLowerCase() : '';
      reportB = b.patient !== null ? b.patient.lastName.toLowerCase() : '';
    }

    if (this.state.sortBy === 'STUDY_START') {
      reportA = reportA !== null ? new Date(reportA) : null;
      reportB = reportB !== null ? new Date(reportB) : null;
    }


    const parseDateTime = (dateTimeStr) => {

      let parsedDate = new Date(dateTimeStr);
      if(!isNaN(parsedDate.getTime())){
        return parsedDate;
      }
      

      const [datePart, timePart] = dateTimeStr.split(' ');
      const [month, day, year] = datePart.split('/').map(num => parseInt(num, 10));
      const [hours, minutes, seconds] = timePart.split(':').map(num => parseInt(num, 10));
      const ampm = dateTimeStr.slice(-2);

      const hours24 = ampm === 'AM' ? 
      (hours === 12 ? 0 : hours) : 
      (hours === 12 ? hours : hours + 12);

      let date = new Date(year, month - 1, day, hours24, minutes, seconds);
      return date;
    
    }

    
    if (this.state.sortBy === 'lastName') {
      reportA = a.patient !== null ? a.patient.lastName.toLowerCase() : '';
      reportB = b.patient !== null ? b.patient.lastName.toLowerCase() : '';
    }
    // reportA = reportA === 'NaN' ? '' : reportA;
    // reportB = reportB === 'NaN' ? '' : reportB;
  
    if (this.state.sortBy === 'STUDY_START') {
      reportA = parseDateTime(a.data?.STUDY_START ?? a.startTime);
      reportB = parseDateTime(b.data?.STUDY_START ?? b.startTime);
    
    } else {
      reportA = a.data?.[this.state.sortBy] ?? a.patient?.lastName.toLowerCase() ?? '';
      reportB = b.data?.[this.state.sortBy] ?? b.patient?.lastName.toLowerCase() ?? '';
  
      // Default comparison logic
      
      if (reportA > reportB) {
        comparison = 1;
      } else if (reportA < reportB) {
        comparison = -1;
      } else {
        comparison = 0;
      }
    }
  
    if (this.state.sortBy === 'STUDY_START') {
      comparison = reportA.getTime() - reportB.getTime();
    }
  
    // Adjust comparison based on sortOrder
    if (this.state.sortOrder < 0) {
      comparison *= -1;
    }
  
    return comparison;
  }
  

  renderCharts() {
    var isPatientView = window.location.hash.includes('patients')
      ? true
      : false;
    if (!isPatientView) return;
    if (this.state.includeInMultiNight.length > 1) {
      return (
        <div className="border border-muted rounded">
          <div className="row">
            {['rdi', 'bd', 'cs', 'ai'].map((dataId) => (
              <div className="col" key={dataId}>
                <GenericChart
                  dataId={dataId}
                  reports={this.state.includeInMultiNight}
                  parent={this}
                />
              </div>
            ))}
          </div>
        </div>
      );
    }
  }

  renderSortArrow(category) {
    if (this.state.sortBy === category) {
      if (this.state.sortOrder > 0) {
        return (
          <i
            onClick={this.setSortBy.bind(this)}
            id={category}
            className="fal fa-sort-up fa-xs"
          ></i>
        );
      } else {
        return (
          <i
            onClick={this.setSortBy.bind(this)}
            id={category}
            className="fal fa-sort-down fa-xs"
          ></i>
        );
      }
    } else {
      return (
        <i
          onClick={this.setSortBy.bind(this)}
          id={category}
          className="fal fa-sort fa-xs"
        ></i>
      );
    }
  }

  render() {
    let reportItems = this.state.limitedReports.map(this.renderListItem);
    let isPatientView = window.location.hash.includes('patients')
      ? true
      : false;
    let generateMultiNight = this.generateMultiNight.bind(this);
    let hidden =
      this.state.includeInMultiNight.length <= 1 ||
      !window.location.hash.includes('patients')
        ? true
        : false;

    let loading;
    if (this.state.fetchingReports) {
      loading = <span>Loading Reports . . .</span>;
    }

    let dateSort = this.renderSortArrow('STUDY_START');
    let patientSort = this.renderSortArrow('lastName');
    let RDISort = this.renderSortArrow('RDI4_OVERALL');
    let BDSort = this.renderSortArrow('SPO2_BASELINE_LOW');
    let CTSort = this.renderSortArrow('SPO2_INST_PCT');
    let VRTSort = this.renderSortArrow('VRT');
    let showPostOP = false;
    if (this.state.reportAlertSettings) {
      showPostOP = this.state.reportAlertSettings.showPostOP;
    }

    return (
      <div className="card">
        <div className="card-header">
          <div className="row" style={{ height: 32 }}>
            <span className="h4 col">Reports</span>
            <div className="form-horizontal row">
              <div className="form-group form-inline">
                <label className="col">From: </label>
                <input
                  className="form-control-sm col"
                  name="fromDate"
                  type="date"
                  onChange={this.handleSearchChange}
                  value={this.state.fromDate}
                />
              </div>
              <div className="form-group form-inline">
                <label className="col">To: </label>
                <input
                  className="form-control-sm col"
                  name="toDate"
                  type="date"
                  onChange={this.handleSearchChange}
                  value={this.state.toDate}
                />
              </div>
              <div className="form-group col">
                <button
                  className="btn btn-sm btn-outline-primary"
                  type="submit"
                  onClick={this.handleSearch}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body" style={{ padding: 0 }}>
          <form onSubmit={this.handleSearch}>
            <div className="form-row">
              <div className="form-check m-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  defaultChecked={this.state.hidePending}
                  onChange={this.togglePending}
                />
                <label className="form-check-label">Hide Pending</label>
              </div>
              <div className="form-check m-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  defaultChecked={this.state.hideComplete}
                  onChange={this.toggleComplete}
                />
                <label className="form-check-label">Hide Completed</label>
              </div>
              <div className="form-check m-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  defaultChecked={this.state.hideInvalid}
                  onChange={this.toggleInvalid}
                />
                <label className="form-check-label">Hide Invalid</label>
              </div>
              <div className="form-check m-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  defaultChecked={this.state.showPhysicians}
                  onChange={this.toggleShowPhysicians}
                />
                <label className="form-check-label">Show Physicians</label>
              </div>
            </div>
          </form>
          <div>
            <ul className="list-group mb-2">
              <li
                className="list-group-item lead text-center"
                style={{ padding: 2 }}
              >
                <div className="row ">
                  <div className="col-2">
                    <span id="STUDY_START" onClick={this.setSortBy.bind(this)}>
                      Date {dateSort}{' '}
                    </span>
                  </div>
                  <div className="col-1" hidden={isPatientView}>
                    <span id="name" onClick={this.setSortBy.bind(this)}>
                      Patient {patientSort}
                    </span>
                  </div>
                  <div className="col-1" hidden={!this.state.showPhysicians}>
                    <span id="physicians">Physicians </span>
                  </div>
                  <div className="col-1">
                    <span onClick={this.setSortBy.bind(this)} id="RDI4_OVERALL">
                      RDI {RDISort}{' '}
                    </span>
                  </div>
                  <div className="col-1">
                    <span
                      id="SPO2_BASELINE_LOW"
                      onClick={this.setSortBy.bind(this)}
                    >
                      BL {BDSort}
                    </span>
                  </div>
                  <div className="col-1">
                    <span
                      id="SPO2_INST_PCT"
                      onClick={this.setSortBy.bind(this)}
                    >
                      CT% {CTSort}
                    </span>
                  </div>
                  <div className="col-2" hidden={!showPostOP}>
                    <span id="postOP">Post Op Risk</span>
                  </div>
                  <div className="col-1">
                    <span id="VRT" onClick={this.setSortBy.bind(this)}>
                      VRT {VRTSort}
                    </span>
                  </div>
                  <div className="col-1" hidden={!isPatientView}>
                    <span>Compare Reports?</span>
                  </div>
                  <div className="col-3">
                    <span></span>
                  </div>
                </div>
              </li>
              {loading}
              {reportItems}
            </ul>
          </div>
          <div className="row">
            <div className="col"></div>
            <div className="col">
              <nav>
                <Paginator
                  data={this.state.filteredReports}
                  limit={this.state.limit}
                  render={this.renderReports}
                />
              </nav>
            </div>
            <div className="col">
              <div className="row justify-content-center">
                <button
                  className="btn btn-outline-primary multi-night-pdf-button"
                  hidden={hidden}
                  onClick={generateMultiNight}
                >
                  Multi-Night PDF
                </button>
              </div>
            </div>
          </div>
          {this.renderCharts()}
        </div>
      </div>
    );
  }
}

export default Reports;
