import React, {Component} from 'react'
import $ from 'jquery'
import MemberListItem from './member-list-item'
import Invite from './invite'

class Members extends Component {

	constructor(props){
		super(props)
		this.state = {
			email: '',
			members: []
		}
		this.renderMember = this.renderMember.bind(this)
		this.roleChangeHandler = this.roleChangeHandler.bind(this)
		this.fetch = this.fetch.bind(this)
	}

	componentDidMount(){
		this.fetch()
	}

	componentDidUpdate(prevProps,prevState){
		var orgChanged = prevProps.organization.id !== this.props.organization.id
		if (orgChanged){
			this.fetch()
		}
	}

	fetch(){
		var success = this.dataHandler.bind(this)
		var url = '/members?organizationId=' + this.props.organization.id 
		$.get(url).done(success)
	}

	dataHandler(data){
		this.setState({
			members: data.rows
		})
	}

	roleChangeHandler(event){
		var memberId = event.target.getAttribute('member-id')
		var url = '/members/' + memberId
		var data = {
			userId: event.target.id,
			roleId: event.target.value,
			organizationId: this.props.organization.id
		}
		$.ajax({
			url: url,
			method: 'PUT',
			data: data
		})
	}

	handleRemoveSuccess(){
		this.fetch()
	}

	handleRemoveFailure(res){
		var data = res.responseJSON
		alert(data.error.message)
	}

	renderMember(member,index){
		return <MemberListItem 
			key={index}
			user={this.props.user}
			userId={member.userId} 
			roleId={member.roleId}
			memberId={member.id}
			isAdmin={this.props.isAdmin}
			organizationId={this.props.organization.id}
			fetch={this.fetch}
			removeHandler={this.fetch}
			/>
	}

	render(){
		var members = this.state.members.map(this.renderMember)
		return (
			<div>
				<div className=''>
					<Invite organization={this.props.organization} 
						successHandler={this.fetch}/>
				</div>
				<div className=''>
					<div className='card'>
						<div className='card-header'>
							<span className='h4 mt-4 mb-4'>Members</span>
						</div>
						<div className='card-body'>
							<div className='row'>
								<div className='col'>
									<ul className='list-group'>
										{members}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	
}

export default Members
